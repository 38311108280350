import { Navbar, Container, Nav } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedium, faTwitter, faInstagram, faTelegram, faDiscord } from "@fortawesome/free-brands-svg-icons";

const Navbarcomp = () => {
    return (
        <div>
            <Navbar id="home" className="fixed-top" collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="./img/logoJamkeys.png"
                            width="160"
                            className="d-inline-block align-top"
                            alt="Boto NFT"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto justify-content-center text-center fw-bold fst-italic">
                            <Nav.Link className="text-uppercase p-4 h5 mt-2" href="https://medium.com/@jambocrypto/jambo-launches-its-own-nft-collection-titled-jamkeys-929aaa58a21e" target={'_blank'}> All about JamKeys NFT here</Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5 mt-2" href="https://jambonft.com" target={'_blank'}> Return to Jambo website</Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5" href="https://medium.com/@jambocrypto" target={'_blank'}>
                                <FontAwesomeIcon icon={faMedium} size="2x"></FontAwesomeIcon>
                            </Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5" href="https://discord.gg/UpUZuHMTaV" target={'_blank'}>
                                <FontAwesomeIcon icon={faDiscord} size="2x"></FontAwesomeIcon>
                            </Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5" href="https://t.me/jambocrypto" target={'_blank'}>
                                <FontAwesomeIcon icon={faTelegram} size="2x"></FontAwesomeIcon>
                            </Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5" href="https://twitter.com/Jambocrypto" target={'_blank'}>
                                <FontAwesomeIcon icon={faTwitter} size="2x"></FontAwesomeIcon>
                            </Nav.Link>
                            <Nav.Link className="text-uppercase p-4 h5" href="https://www.instagram.com/jambocrypto/" target={'_blank'}>
                                <FontAwesomeIcon icon={faInstagram} size="2x"></FontAwesomeIcon>
                            </Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
export default Navbarcomp;