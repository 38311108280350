import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faMedium, faInstagram, faTelegram, faDiscord } from "@fortawesome/free-brands-svg-icons";

const Link = styled("a")`
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    @media (max-width: 700px) {
        position: initial;
    }
`

const FooterPage = () => {
    return (
        <div className="p-3 text-center align-items-center" style={{ background: "#000", color: "#FFF", alignItems: "center" }}>
            <Container>
                <Row className="text-center align-items-center pt-2">
                    <Col md={4}>
                        <text>© 2022 JamKeys NFT</text>
                    </Col>
                    <Col md={4}>
                        <Link target="_blank" href="https://jambonft.com"><img
                            src="./img/logoJamkeysbw.png"
                            width="130"
                            className="img.desaturate d-inline-block align-top"
                            alt="JAMKEYS NFT By Jambo Universe!"
                        />
                        </Link>
                    </Col>
                    <Col md={4}>
                    <Link href="https://medium.com/@jambocrypto" target={'_blank'}><FontAwesomeIcon className="p-2" icon={faMedium} size="1x"></FontAwesomeIcon></Link>
                        <Link href="https://twitter.com/Jambocrypto" target={'_blank'}><FontAwesomeIcon className="p-2" icon={faTwitter} size="1x"></FontAwesomeIcon></Link>
                        <Link href="https://discord.gg/UpUZuHMTaV" target={'_blank'}><FontAwesomeIcon className="p-2" icon={faDiscord} size="1x"></FontAwesomeIcon></Link>
                        <Link href="https://www.instagram.com/jambocrypto/" target={'_blank'}><FontAwesomeIcon className="p-2" icon={faInstagram} size="1x"></FontAwesomeIcon></Link>
                        <Link href="https://t.me/jambocrypto" target={'_blank'}><FontAwesomeIcon className="p-2" icon={faTelegram} size="1x"></FontAwesomeIcon></Link>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}
export default FooterPage;